/* eslint-disable no-useless-computed-key */
import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { FaMixcloud, FaPhoneAlt, FaSms } from "react-icons/fa";
import { GoDotFill } from "react-icons/go";
import { IoLogoWhatsapp } from "react-icons/io5";
import { PiFileHtml } from "react-icons/pi";
import { TbMessageChatbot } from "react-icons/tb";
import { fetchController } from "../../utils/FetchController/fetchController";
import Loader from "../../utils/Loader/Loader";
import AddWidget from "../Modals/AddWidgets";
import Table from "../Tables/Table";
import WidgetTour from "./WidgetTour";
import {
  MdOutlineLeaderboard,
  MdOutlineWebAsset,
  MdOutlineWifiCalling3,
} from "react-icons/md";
import { getHostDomain } from "../../utils/utils";
import { AgencyContext } from "../Settings/AgencyProvider/AgencyProvider";
import { FiPhoneMissed } from "react-icons/fi";
import { FaFacebookMessenger, FaInstagram } from "react-icons/fa6";
import Paginator from "../../utils/Loader/Paginator";

const Widgets = ({ tab }) => {
  const baseUrl = getHostDomain();
  
  const [status, setStatus] = useState("idle");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isInitialLoad, setIsInitialLoad] = useState(true); // New state variable

  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(0);

  const [tableData, setTableData] = useState([]);
  const [heads, setHeads] = useState([]);
  const agency = useContext(AgencyContext);
  const { textColor, buttonColorScheme } = agency;

  const endpoint = `/api/v1/widget/get_my_details?size=10&page=${page}`;

  const hitFetch = async () => {
    try {
      setStatus("loading");
      const urlHit = baseUrl + endpoint;

      const getData = await fetchController(urlHit, "GET");
      setPages(getData?.data?.pages);

      if (getData?.data) {
        const updatedData = getData.data.items.map((item) => {
          return {
            type:
              item.widget_type === "html" ? (
                <PiFileHtml />
              ) : item.widget_type === "html_call" ? (
                <MdOutlineWebAsset />
              ) : item.widget_type === "whatsapp" ? (
                <IoLogoWhatsapp />
              ) : item.widget_type === "phone" ? (
                <FaPhoneAlt />
              ) : item.widget_type === "sms" ? (
                <FaSms />
              ) : item.widget_type === "leadconnector" ? (
                <MdOutlineLeaderboard />
              ) : item.widget_type === "leadconnector_call" ? (
                <FiPhoneMissed />
              ) : item.widget_type === "fb_messenger" ? (
                <FaFacebookMessenger />
              ) : item.widget_type === "web_call" ? (
                <MdOutlineWifiCalling3 />
              ) : item.widget_type === "instagram" ? (
                <FaInstagram />
              ) : item.widget_type === "plivo_call" ? (
                <FaMixcloud />
              ) : (
                <TbMessageChatbot />
              ),
            status:
              item.status ||
              item.widget_type === "phone" ||
              item.widget_type === "html_call" ||
              item.widget_type === "leadconnector" ||
              item.widget_type === "leadconnector_call" ||
              item.widget_type === "sms" ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    marginLeft: "15px",
                  }}
                >
                  <Tooltip label="Live" aria-label="Live">
                    <Center>
                      <Icon as={GoDotFill} color="green.500" />
                    </Center>
                  </Tooltip>
                </div>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "left",
                    marginLeft: "15px",
                  }}
                >
                  <Tooltip
                    label="Add Data Sources"
                    aria-label="Add Data Sources"
                  >
                    <Center>
                      <Icon as={GoDotFill} color="red.500" />
                    </Center>
                  </Tooltip>
                </div>
              ),
            assistant_id: item.assistant_id,
            assistant: item.assistant_name,
            ["data_Source"]: (
              <div style={{ marginLeft: "35px" }}>
                {item.widget_type === "phone"
                  ? "-"
                  : item.data_sources_count.toLocaleString()}
              </div>
            ),
            name: item.name,
            description: item.description,
            widget_type: item.widget_type,
            id: item.id,
          };
        });
        const heads = [
          "type",
          "name",
          "description",
          "assistant",
          "data_Source",
        ];
        const columns = heads.map((key) => ({
          Header: key,
          accessor: key,
          Filter: ({ column }) => <input {...column.filterProps} />,
        }));
        setTableData(updatedData);
        setHeads(columns);
        setStatus("success");
        setIsInitialLoad(false); // Set isInitialLoad to false after the initial load
      }
    } catch (error) {
      setStatus("failure");
    }
  };
  useEffect(() => {
    if (tab === "Widgets") {
      hitFetch();
    }
  }, [tab, page]);

  const loading = status === "loading" && isInitialLoad;
  const [widgetTourVisibility, setWidgetTourVisibility] = useState(
    localStorage.getItem("widgetTourVisibility") ? false : true
  );
  const onCloseWidgetTour = () => {
    localStorage.setItem("widgetTourVisibility", false);
    setWidgetTourVisibility(false);
  };

  const onGotoFirstPage = () => setPage(1);

  return (
    <>
      <Box
        border="1px"
        p="5"
        height={"81vh"}
        overflowY="scroll"
        borderRadius="md"
      >
        <Heading color={textColor} fontSize="xl">
          Widgets
        </Heading>
        {widgetTourVisibility ? (
          <WidgetTour onCloseWidgetTour={onCloseWidgetTour} />
        ) : null}
        <Flex justifyContent="flex-end" alignItems="center" mb="4">
          <Button
            colorScheme={buttonColorScheme}
            onClick={() => {
              onOpen();
            }}
          >
            Add a Widget
          </Button>
        </Flex>
        {loading ? (
          <Loader />
        ) : (
          <Box height="auto">
            <Table
              fetch={hitFetch}
              columns={heads}
              data={tableData}
              table="widget"
            />

            <Flex
              justifyContent={"flex-end"}
              alignItems={"center"}
              marginBlock={2}
            >
              <Paginator
                colorScheme={buttonColorScheme}
                onChangePage={setPage}
                page={page}
                pages={pages}
              />
            </Flex>
          </Box>
        )}
      </Box>
      <AddWidget
        fetchAssistants={hitFetch}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        onGotoFirstPage={onGotoFirstPage}
      />
    </>
  );
};

export default Widgets;
